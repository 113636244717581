
let DataPage = {
    "config"           : {},
    "access_dash"      : [],
    "access_dash_site" : [],
    "general_tour"     : [],
    "solar"            : [],
    "project"          : [],
    "screen_initial"   : [],
    "gallery"          : [],
    "video"            : [],
    "virtual_tour"     : [],
    "map_poi"          : [],
    "map_2"            : [],
    "map_list_type"    : [],
    "apartments"       : [],
    "apartments_file"  : [],
    "floorplans"       : []
};

let NotifyDataPage = {
    "config"           : [],
    "access_dash"      : [],
    "access_dash_site" : [],
    "general_tour"     : [],
    "solar"            : [],
    "project"          : [],
    "screen_initial"   : [],
    "gallery"          : [],
    "video"            : [],
    "virtual_tour"     : [],
    "map_poi"          : [],
    "map_2"            : [],
    "map_list_type"    : [],
    "apartments"       : [],
    "apartments_file"  : [],
    "floorplans"       : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["config"].forEach(element => {           element(value['config']); });
    NotifyDataPage["access_dash_site"].forEach(element => { element(value['access_dash_site']); });
    NotifyDataPage["access_dash"].forEach(element => {      element(value['access_dash']); });
    NotifyDataPage["general_tour"].forEach(element => {     element(value['general_tour']); });
    NotifyDataPage["solar"].forEach(element => {            element(value['solar']);});
    NotifyDataPage["project"].forEach(element => {          element(value['project']); });
    NotifyDataPage["screen_initial"].forEach(element => {   element(value['screen_initial']);});
    NotifyDataPage["gallery"].forEach(element => {          element(value['gallery']); });
    NotifyDataPage["video"].forEach(element => {            element(value['video']); });
    NotifyDataPage["virtual_tour"].forEach(element => {     element(value['virtual_tour']); });
    NotifyDataPage["map_poi"].forEach(element => {          element(value['map_poi']); });
    NotifyDataPage["map_2"].forEach(element => {            element(value['map_2']); });
    NotifyDataPage["map_list_type"].forEach(element => {    element(value['map_list_type']); });
    NotifyDataPage["apartments"].forEach(element => {       element(value['apartments']); });
    NotifyDataPage["apartments_file"].forEach(element => {  element(value['apartments_file']); });
    NotifyDataPage["floorplans"].forEach(element => {       element(value['floorplans']); });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}

export function UnRegisterDataPage(key, callback){
    if(NotifyDataPage[key].length > 0){
        NotifyDataPage[key] = NotifyDataPage[key].filter((item) => {
            return item !== callback;
        });
    }
}