
import { useState } from "react";

import './Login.css';

import { Access } from "services/AccessLogin";
import { SvgLogoTipo } from "services/SvgFile";

import { SetModalState } from "interface/PopUp";

import PopUP_RecoverPass from "components/PopUp/RecoverPass";

export default function Login(props){

    const [ email, setEmail ]       = useState('');
    const [ password, setPassword ] = useState('');
    const [ msgError, setMsgError ] = useState('');

    function saveData(event){
        event.preventDefault();
        props.setLoading(true);
        Access('logar', email, password, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        props.loadingDataPage();
    }

    function CallbackError(){
        props.setLoading(false);
        setMsgError('E-mail ou senha incorreta!');
    }

    return(
        <>
            <form className="Login" onSubmit={ saveData }>
                <div className="title_page">
                    THE REAL ESTATE PLATFORM
                </div>
                <div className="content">
                    <div className="div_logo">                        
                        <img alt="logo" src="./logo192.png" className="logo" />
                    </div>
                    <div className="div_input">
                        <input type="email" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email.replaceAll(' ', '') } required placeholder="exemplo@site.com.br" />
                        <span className="name_input">E-mail*</span>
                    </div>
                    <div className="div_input div_input_login">
                        <input type="password" onChange={ (e)=>{ setPassword(e.target.value) } } value={ password.replaceAll(' ', '') } required placeholder="******" />
                        <span className="name_input">Senha*</span>
                    </div>
                    <div className={ msgError ? "msg_error" : "" }>
                        { msgError }
                    </div>
                    <div className="div_logar">
                        <button type="submit" className="button">Entrar</button>
                    </div>
                    <div className="recover_pass">
                        <div className="new_pass" />
                        <div className="open_recover" onClick={ ()=>{ SetModalState('RecoverEmail', true) } }>
                            Recuperar a senha
                        </div>
                    </div>
                </div>
            </form>
            <PopUP_RecoverPass setLoading={ props.setLoading } />
        </>
    )
}
