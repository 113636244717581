import { useState, useEffect } from "react";

import './Config.css';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_Config } from "services/RegisterData";
import { SvgDelete, SvgFont, SvgImg } from "services/SvgFile";

import TopicPage from "components/TopicPage";

export default function Page_Config(props){

    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('config'));

    const [ timeRestart, setTimeRestart ] = useState(dataPage.time_restart);
    const [ logotipo, setLogotipo ]       = useState(dataPage.logotipo);
    const [ statusLogo, setStatusLogo ]   = useState(false);

    const [ floorplanTitle, setFloorplanTitle ]   = useState(dataPage.floorplan_title);
    const [ floorplanFile, setFloorplanFile ]     = useState(dataPage.floorplan_file);
    const [ floorplanStatus, setFloorplanStatus ] = useState(false);

    const [ font, setFont ] = useState(dataPage.list_fonte);

    function AddFile(type, value){
        // logotipo
        if(type == 'logotipo'){
            if(value){
                setLogotipo(value);
                setStatusLogo(true);
            }else {
                setLogotipo('');
                setStatusLogo(false);
            }
        }
        // floorplanFile
        if(type == 'floorplanFile'){
            if(value){
                setFloorplanFile(value);
                setFloorplanStatus(true);
            }else {
                setFloorplanFile('');
                setFloorplanStatus(false);
            }
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(id, index, value){
        if(id == 0){
            const newData = [...font];
            newData.splice(index, 1);
            setFont(newData);

        }else {
            SetModalData('Confirmation', { "origin": "config", "type": "delete_data", "id" : id, "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function HandleData(type, index, value){
        const newData = [...font];
        if(type == 'fonte'){
            if(value){
                newData[index]['fonte']   = value;
                newData[index]['status'] = true;
            }else {
                newData[index]['fonte']   = value;
                newData[index]['status'] = false;
            }
        }else {
            newData[index][type] = value;
        }
        setFont(newData);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Config(userId, timeRestart, logotipo, floorplanTitle, floorplanFile, font, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('config', setDataPage);

        return ()=>{
            UnRegisterDataPage('config', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('config'));

        setTimeRestart(dataPage.time_restart)
        setLogotipo(dataPage.logotipo);
        setStatusLogo(false);

        setFloorplanTitle(dataPage.floorplan_title);
        setFloorplanFile(dataPage.floorplan_file);
        setFloorplanStatus(false);

        setFont(dataPage.list_fonte);
    }, [dataPage]);

    return(
        <div className="Page_Config">
            <form onSubmit={ SaveData }>
                <TopicPage type="0" ReturnPage={ ()=>{ } } title="Dados do projeto" />

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados fixos</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <input type="text" className="time_restart" onChange={ (e)=>{ setTimeRestart(e.target.value) } } value={ timeRestart.replace(/[^0-9]/g, '') } maxLength="2" required />
                            <span className="name_input">Time to restart</span>
                        </div>
                        <span className="div_input div_add_img">
                            <div className={ statusLogo ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { statusLogo ? "Logo fixo adicionado" : "Adicionar logo fixo" }
                                    <input type="file" onChange={ (e)=>{ AddFile('logotipo', e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                logotipo !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile('logotipo', ''); } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        statusLogo ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(logotipo)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', 'logo/' + logotipo) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Floorplan</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setFloorplanTitle(e.target.value) } } value={ floorplanTitle } maxLength="140" />
                            <span className="name_input">Título</span>
                        </div>
                        <span className="div_input div_add_img">
                            <div className={ floorplanStatus ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { floorplanStatus ? "Logo fixo adicionado" : "Adicionar logo fixo" }
                                    <input type="file" onChange={ (e)=>{ AddFile('floorplanFile', e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                floorplanFile !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile('floorplanFile', ''); } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        floorplanStatus ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(floorplanFile)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', 'apartments/' + floorplanFile) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Lista das fontes do site</div>
                        <div className="new_data_page" onClick={ ()=>{ setFont([...font, { "id": 0, "title": "", "fonte": "", "status": false }]) } }>
                            Adicionar nova fonte
                        </div>
                    </div>
                    {
                        font.length > 0 ?
                        <>
                            <div className="inf_date">
                                *Aceita apenas arquivos .otf e .ttf
                            </div>
                            {                                
                                font.map((elem, index)=>{
                                    return(
                                        <div className="list_input_data border_top" key={ index }>
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleData('title', index, e.target.value) } } value={ elem.title } maxLength="140" />
                                                <span className="name_input">Nome da fonte</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ elem.status ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { elem.status ? "Fonte adicionada" : "Adicionar fonte" }
                                                        <input type="file" onChange={ (e)=>{ HandleData('fonte', index, e.target.files[0]); } } accept=".otf, .ttf" />
                                                    </label>
                                                </div>
                                                {
                                                    elem.fonte !='' ?
                                                    <>
                                                        <div className="delete_img_add" onClick={ ()=>{ HandleData('fonte', index, ''); } }>
                                                            <SvgDelete className="icons" color="#ffffff" />
                                                        </div>
                                                        {
                                                            elem.status ?
                                                            <a href={ URL.createObjectURL(elem.fonte) } className="show_img_">
                                                                <SvgFont color="#324d6b" className="icons"/>
                                                            </a> :
                                                            <a href={ elem.fonte } className="show_img_">
                                                                <SvgFont color="#324d6b" className="icons"/>
                                                            </a>
                                                        }
                                                    </>
                                                    : null
                                                }
                                            </span>
                                            <div className="div_input" onClick={ ()=>{ DeleteData(elem.id, index, elem.title); } }>
                                                <SvgDelete color="#f00000" className="icons" />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </> 
                        :
                        <div className="no_data">Nenhuma fonte adicionada até o momento...</div>
                    }
                </div>
            </form>
        </div>
    )
}