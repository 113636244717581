import { useState, useEffect } from "react";

import { SvgSave, SvgSeta } from "services/SvgFile";

export default function TopicPage(props){

    return(
        <div className="new_block">
            {
                props.type == 0 ? null : 
                <div className="div_name_page">
                    <div className="show_icon">
                        <SvgSeta color="#2D3C53" className="icons" />
                    </div>
                    <div className="page_return" onClick={ ()=>{ props.ReturnPage() } }>
                        Voltar
                    </div>
                </div>
            }
            <div className="highlight_title_page">
                { props.title }
            </div>
            <button className="list_opt_alt_page">
                <div className="div_add_new_data">
                    <div className="new_data_add">
                        <SvgSave color="#ffffff" className="icons" />
                    </div>
                    <div className="name_btn_add">Salvar</div>
                </div>
            </button>
        </div>
    )
}