import { useState, useEffect } from "react";

import './Details.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SvgDelete, SvgImg, SvgSave, SvgSetaRight, SvgStar } from "services/SvgFile";

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import { Reg_Building } from "services/RegisterData";
import TopicPage from "components/TopicPage";

export default function Page_Building(props){

    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage("apartments_file"));

    const [ img, setImg ]                 = useState(dataPage);
    const [ compr, setCompr ]             = useState([]);
    const [ statusCompr, setStatusCompr ] = useState(false);

    function AddCompr(value){
        if(value){
            setCompr(value);
            setStatusCompr(true);
        }else {
            setCompr('');
            setStatusCompr(false);
        }
    }

    function DeleteData(type, id, value){
        SetModalData('Confirmation', { "origin": "apartments", "type" : type, "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Building(userId, compr, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'apartments');
        SetListPag('currentPageId', 'remuve');
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('apartments_file', setDataPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('apartments_file', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("apartments_file"));

        setImg(dataPage);
        setCompr([]);
        setStatusCompr(false);
    }, [dataPage]);
    
    return(
        <div className="Page_Building">
            <form onSubmit={ SaveData }>
                <TopicPage type="1" ReturnPage={ ReturnPage } title="Fotos do prédio" />
                
                <div className="list_input_data show_list_file">
                    <div className="space_div div_add_img">
                        {
                            img.length == 0 ? null :
                            <div className="delete_all" onClick={ ()=>{ DeleteData('delete_all', 0, "Todas as imagens") } }>
                                Deletar todas as imagens
                            </div>
                        }

                        <div className={ statusCompr == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                            <label>
                                { statusCompr == true ? "Fotos adicionadas" : "Adicionar fotos" }
                                <input type="file" onChange={ (e)=>{ AddCompr(e.target.files); } } accept="image/*" multiple />
                            </label>
                        </div>
                        {
                            compr == '' ? null :
                            <div className="delete_img_add" onClick={ ()=>{ AddCompr(''); } }>
                                <SvgDelete className="icons" color="#ffffff" />
                            </div>
                        }
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_input_data">
                        {
                            img.length > 0 ?
                            img.map((elem, index)=>{
                                return(
                                    <div className="div_list_file" key={ index }>
                                        <div className="numb_img">{ index + 1 }</div>
                                        <div className="name_img">
                                            { elem.file }
                                            {
                                                elem.imgFixed ? 
                                                <div data-tooltip-id={ "type_data_" + elem.id } data-tooltip-content="Imagem fixada" data-tooltip-place="top" onClick={ ()=>{ OpenFile("showImg", 'apartments/mask/' + elem.mask) } }>
                                                    <SvgStar className="icons" color="#0b711c" />
                                                </div> : null 
                                            }
                                            <Tooltip id={ "type_data_" + elem.id } />
                                        </div>
                                        <div className="delete_img">
                                            <div className="show_img_" onClick={ ()=>{ OpenFile("showImg", 'apartments/' + elem.file) } }>
                                                <img alt={ "img_" + index } src={ './assets/apartments/thumbnail/thumbnail_' + elem.file } className="icons_file" />
                                            </div>
                                        </div>
                                        <div className="delete_img" onClick={ ()=>{ DeleteData('delete_building', elem.id, elem.file) } }>
                                            <SvgDelete color="#f00000" className="icons" />
                                        </div>
                                    </div>
                                )
                            })
                            : 
                            <div className="no_data">Nenhuma foto adicionada até o momento...</div>
                        }                             
                    </div>
                </div>
            </form>
        </div>
    )
}