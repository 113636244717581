import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';
import { SetListDataSingle } from 'interface/Data';

import { cookiesRegister, typeCookieEmail, typeCookiePageId, typeCookiePassw } from 'fixedData';

// Alt Status data
export function Reg_AltStatus(userId, type, id, origin, status, CallbackSuccess, CallbackError){
    const data    = new FormData();
    data.append("id", id);
    data.append('userId', userId);
    data.append('type_page', origin);
    data.append('edit_data', type);
    data.append('status', status);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/page/' + origin + '.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        switch (origin) {
            case "apartments":
                    SetListDataSingle('apartments', response.data['apartments']);
                    SetListDataSingle('apartments_file', response.data['apartments_file']);
                break;
        
            default:
                    SetListDataSingle(origin, response.data[origin]);
                break;
        }
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}


// Recover pass
export function CheckedEmail(email, CallbackSuccess, CallbackError, CallbackError_Send, CallbackError_NotEmail){
    const data    = new FormData();

    data.append('type_page', "recover_email");
    data.append('email', email);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/inc/recover_pass.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        switch (response.data) {
            case "Email enviado com sucesso":
                    CallbackSuccess();
                break;
            case "Email não enviado":
                    CallbackError_Send();
                break;
            case "E-mail não encontrado":
                    CallbackError_NotEmail();
                break;
            case "Erro ao enviar mensagem":
                    CallbackError();
                break;
        }

    }).catch((error)=>{

        CallbackError();
    })
}
// Checked pass or alt pass
export function CodeNewPass(type, pass, code, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', type);
    data.append('pass', pass);
    data.append('code', code);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/inc/recover_pass.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        if(response.data == "conectado"){
            CallbackSuccess();
        }else {
            CallbackError();
        }

    }).catch((error)=>{
        CallbackError();
    })
}


// Edit or register data user
export function Reg_UserDash(userId, idPage, name, email, file, pass, nivel, note, page, CallbackSuccess, CallbackError){
    const data    = new FormData();
    const cookies = new Cookies();

    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);
    data.append('nivel', nivel);
    data.append('note', note);

    // new user
    if(idPage == 0){
        page.forEach((elem, index) => {
            data.append('page_id[]', 0);
            data.append('id_project[]', elem.id);
            data.append('type[]', elem.type);
        });

    // edit user
    }else {
        page.forEach((elem, index) => {        
            data.append('page_id[]', elem.id);
            data.append('id_project[]', elem.id_project);
            data.append('type[]', elem.type);
        });
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/page/access_dash.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('access_dash', response.data.access_dash);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }

        if(userId == idPage){
            const newData = response.data.access_dash.find(item => item.id == userId);
            cookies.set(typeCookieEmail, email, '/', cookiesRegister);
            if(pass !=''){
                cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
            }

            SetUserData('id', newData['id']);
            SetUserData('file', newData['file']);
            SetUserData('name', newData['name']);
            SetUserData('email', newData['email']);
            SetUserData('access', newData['access']);
            SetUserData('page', newData['page']);
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Edit user logado
export function Reg_UserLogado(userId, name, email, pass, file, CallbackSuccess, CallbackError){
    const data    = new FormData();
    const cookies = new Cookies();

    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_user');

    data.append('id', userId);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/page/access_dash.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('access_dash', response.data.access_dash);        
        
        const newData = response.data.access_dash.find(item => item.id == userId);
        SetUserData('file', newData['file']);
        SetUserData('name', newData['name']);
        SetUserData('email', newData['email']);

        // current date
        const day = new Date();

        // add 3 day
        const add3Days = new Date();
        add3Days.setDate(day.getDate() + 2);
        cookies.set(typeCookieEmail, email, { path: '/', expires: add3Days }, cookiesRegister);
        if(pass !=''){
            cookies.set(typeCookiePassw, response.data.pass, { path: '/', expires: add3Days }, cookiesRegister);
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Config
export function Reg_Config(idPage, timeRestart, logotipo, floorplanTitle, floorplanFile, font, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'config');
    data.append('edit_data', 'edit_or_register');

    data.append('idPage', idPage);
    data.append('time_restart', timeRestart);
    data.append('logotipo', logotipo);
    
    data.append('floorplan_title', floorplanTitle);
    data.append('floorplan_file', floorplanFile);

    font.forEach((elem, index) => {
        data.append('font_id[]', elem.id);
        data.append('font_title[]', elem.title);
        data.append('font_file_' + index, elem.fonte);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/page/config.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('config', response.data.config);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}



// General Tour
export function Reg_GeneralTour(userId, idPage, title, cover, text, compr, listPoint, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'general_tour');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('title', title);
    data.append('cover', cover);
    data.append('text', text);

    // desktop
    data.append('img', Object.keys(compr).length);
    Object.keys(compr).map((elem, index)=>{
        data.append('compr[]', compr[index]);     
    });

    // list point
    if(listPoint){
        listPoint.forEach((elem, index) => {
            data.append('pont_name_img[]', elem.img); 
            data.append('pont_name_btn[]', elem.name_btn); 
            if(elem.point){
                elem.point.forEach((elem_1, index_1)=>{
                    data.append('pont_id[]', elem_1.id); 
                    data.append('pont_type[]', elem_1.type); 
                    data.append('pont_title[]', elem_1.title); 
                    data.append('pont_file_' + index_1, elem_1.file); 
                    data.append('pont_text[]', elem_1.text); 
                    data.append('pont_video[]', elem_1.video); 
                })
            }
        });
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/page/general_tour.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('general_tour', response.data.general_tour);

        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Solar
export function Reg_Solar(userId, idPage, title, cover, text, comprDesktop, comprMobile, listPoint, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'solar');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('title', title);
    data.append('cover', cover);
    data.append('text', text);

    // desktop
    data.append('imgDesktop', Object.keys(comprDesktop).length);
    Object.keys(comprDesktop).map((elem, index)=>{
        data.append('comprDesktop[]', comprDesktop[index]);     
    });

    // movile
    data.append('imgMobile', Object.keys(comprMobile).length);
    Object.keys(comprMobile).map((elem, index)=>{
        data.append('comprMobile[]', comprMobile[index]);     
    });

    // list point
    if(listPoint){
        listPoint.forEach((elem, index) => {
            data.append('pont_name_img[]', elem.img); 
            data.append('pont_name_btn[]', elem.name_btn); 
            if(elem.point){
                elem.point.forEach((elem_1, index_1)=>{
                    data.append('pont_id[]', elem_1.id); 
                    data.append('pont_type[]', elem_1.type); 
                    data.append('pont_title[]', elem_1.title); 
                    data.append('pont_file_' + index_1, elem_1.file); 
                    data.append('pont_text[]', elem_1.text); 
                    data.append('pont_video[]', elem_1.video); 
                })
            }
        });
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('solar', response.data.solar);

        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// disassemble
export function Reg_Disassemble(userId, idPage, title, cover, text, comprDesktop, comprMobile, listPoint, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'disassemble');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('title', title);
    data.append('cover', cover);
    data.append('text', text);

    // desktop
    data.append('imgDesktop', Object.keys(comprDesktop).length);
    Object.keys(comprDesktop).map((elem, index)=>{
        data.append('comprDesktop[]', comprDesktop[index]);     
    });

    // movile
    data.append('imgMobile', Object.keys(comprMobile).length);
    Object.keys(comprMobile).map((elem, index)=>{
        data.append('comprMobile[]', comprMobile[index]);     
    });

    // list point
    if(listPoint){
        listPoint.forEach((elem, index) => {
            data.append('pont_name_img[]', elem.img); 
            data.append('pont_name_btn[]', elem.name_btn); 
            if(elem.point){
                elem.point.forEach((elem_1, index_1)=>{
                    data.append('pont_id[]', elem_1.id); 
                    data.append('pont_type[]', elem_1.type); 
                    data.append('pont_title[]', elem_1.title); 
                    data.append('pont_file_' + index_1, elem_1.file); 
                    data.append('pont_text[]', elem_1.text); 
                    data.append('pont_video[]', elem_1.video); 
                })
            }
        });
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle('disassemble', response.data.disassemble);

        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// add point
export function Reg_Point(origin, idPage, imgPoint, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', origin);
    data.append('edit_data', 'add_point');

    data.append('idPage', idPage);

    imgPoint.forEach((elem, index) => {
        let test = elem.imgPoint.split('/');
        data.append('point_height[]', elem.clientHeight);
        data.append('point_width[]', elem.clientWidth);
        data.append('point_id[]', elem.id);
        data.append('point_img[]', test[7]);
        data.append('point_top[]', elem.top);
        data.append('point_left[]', elem.left);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// project
export function Reg_Project(idUser, aboutTitle, aboutText, aboutFile, datasheetTitle, datasheetText, datasheetFile, listDevelopers, listArchitects, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', "project");
    data.append('edit_data', 'edit_or_register');

    data.append('idUser', idUser);
    data.append('about_title', aboutTitle);
    data.append('about_text', aboutText);
    data.append('about_file', aboutFile);
    data.append('datasheet_title', datasheetTitle);
    data.append('datasheet_text', datasheetText);
    data.append('datasheet_file', datasheetFile);

    listDevelopers.forEach((elem, index) => {
        data.append('developers_id[]', elem.id);
        data.append('developers_name[]', elem.name);
        data.append('developers_office[]', elem.office);
        data.append('developers_email[]', elem.email);
        data.append('developers_file_' + index, elem.file);
    });

    listArchitects.forEach((elem, index) => {
        data.append('architects_id[]', elem.id);
        data.append('architects_name[]', elem.name);
        data.append('architects_office[]', elem.office);
        data.append('architects_email[]', elem.email);
        data.append('architects_file_' + index, elem.file);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('project', response.data.project);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// screen initial 
export function Reg_ScreenInitial(idUser, logo, type, cover, title, copywrite, nameBtn, text, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', "screen_initial");
    data.append('edit_data', 'edit_or_register');

    data.append('idUser', idUser);
    data.append('logo', logo);
    data.append('type', type);
    data.append('cover', cover);
    data.append('title', title);
    data.append('copywrite', copywrite);
    data.append('name_btn', nameBtn);
    data.append('text', text);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/page/screen_initial.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('screen_initial', response.data.screen_initial);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// gallery
export function Reg_Gallery(userId, idPage, title, text, file, listImg, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'gallery');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('title', title);
    data.append('text', text);
    data.append('file', file);
    
    listImg.forEach((elem, index) => {
        data.append('list_img_id[]', elem.id); 
        data.append('list_img_title[]', elem.title); 
        data.append('list_img_file_' + index, elem.img); 
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('gallery', response.data.gallery);

        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Floorplans
export function Reg_Floorplans(userId, idPage, title, text, file, listImg, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'floorplans');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('title', title);
    data.append('text', text);
    data.append('file', file);
    
    listImg.forEach((elem, index) => {
        data.append('list_img_id[]', elem.id); 
        data.append('list_img_title[]', elem.title);
        data.append('list_img_bedrooms[]', elem.bedrooms); 
        data.append('list_img_bathrooms[]', elem.bathrooms); 
        data.append('list_img_flex[]', elem.flex); 
        data.append('list_img_file_' + index, elem.img); 
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('floorplans', response.data.floorplans);

        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// video
export function Reg_Video(userId, idPage, title, text, file, listVideo, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'video');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('title', title);
    data.append('text', text);
    data.append('file', file);
    
    listVideo.forEach((elem, index) => {
        data.append('list_video_id[]', elem.id); 
        data.append('list_video_title[]', elem.title); 
        data.append('list_video_type[]', elem.type); 
        data.append('list_video_file[]', elem.file); 
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('video', response.data.video);

        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// virtual tour
export function Reg_VirtualTour(userId, title, text, listLink, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'virtual_tour');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('title', title);
    data.append('text', text);
    
    listLink.forEach((elem, index) => {
        data.append('link_id[]', elem.id); 
        data.append('link_title[]', elem.title); 
        data.append('link[]', elem.link); 
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('virtual_tour', response.data.virtual_tour);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// map poi
export function Reg_MapPoi(userId, title, text, latitude, longitude, listPoint, zoom, inclination, iconSmallWidth, iconSmallHeight, iconAverageWidth, iconAverageHeight, iconBigWidth, iconBigHeight, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'map_poi');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('title', title);
    data.append('text', text);
    data.append('latitude', latitude);
    data.append('longitude', longitude);
    
    data.append('zoom', zoom);
    data.append('inclination', inclination);
    data.append('icon_small_width', iconSmallWidth);
    data.append('icon_small_height', iconSmallHeight);
    data.append('icon_average_width', iconAverageWidth);
    data.append('icon_average_height', iconAverageHeight);
    data.append('icon_big_width', iconBigWidth);
    data.append('icon_big_height', iconBigHeight);
    
    listPoint.forEach((elem, index) => {
        data.append('point_id[]', elem.id); 
        data.append('point_id_category[]', elem.id_category);
        data.append('point_new_category[]', elem.new_category);
        data.append('point_show_icon[]', elem.show_icon);
        data.append('point_title[]', elem.title); 
        data.append('point_latitude[]', elem.latitude); 
        data.append('point_longitude[]', elem.longitude); 
        data.append('point_type[]', elem.type); 
        data.append('point_file_' + index, elem.icon); 
    });
    
    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('map_poi', response.data.map_poi);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// map point
export function Reg_PointMap(lat, lng, type, title, category, newCategory, file, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'map_poi');
    data.append('edit_data', 'point_map');

    data.append('lat', lat);
    data.append('lng', lng);
    data.append('type', type);
    data.append('title', title);
    data.append('category', category);
    data.append('newCategory', newCategory);
    data.append('file', file);
    
    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('map_poi', response.data.map_poi);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
    
}
// type size icon
export function Reg_SizeIcon(userId, idIcon, type, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'map_poi');
    data.append('edit_data', 'type_size_icon');

    data.append('userId', userId);
    data.append('idIcon', idIcon);
    data.append('type', type);
    
    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('map_poi', response.data.map_poi);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
    
}

// map 2
export function Reg_Map2(userId, listMap, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'map_2');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    
    listMap.forEach((elem, index) => {
        data.append('map_id[]', elem.id); 
        data.append('map_title[]', elem.title); 
        data.append('map_file_' + index, elem.file); 
    });
    
    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('map_2', response.data.map_2);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// building
export function Reg_Building(userId, compr, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'apartments_file');
    data.append('edit_data', 'edit_or_register_building');
    data.append('userId', userId);

    data.append('img', Object.keys(compr).length);
    Object.keys(compr).map((elem, index)=>{
        data.append('compr[]', compr[index]);     
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/page/apartments.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('apartments_file', response.data.apartments_file);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// selected img building
export function Reg_SelectedImgBuilding(id, value, mask, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'apartments_file');
    data.append('edit_data', 'selected_img_building');

    data.append('id', id);
    data.append('value', value);
    data.append('mask', mask);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('apartments_file', response.data.apartments_file);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// apartments
export function Reg_Apartments(userId, idPage, status, number, bedrooms, bathrooms, flex, walk, size, cash, guidance, title, text, file, listImg, CallbackSuccess, CallbackError){
    const data    = new FormData();
    const cookies = new Cookies();

    data.append('type_page', 'apartments');
    data.append('edit_data', 'edit_or_register_apartments');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('status', status);
    data.append('number', number);
    data.append('bedrooms', bedrooms);
    data.append('bathrooms', bathrooms);
    data.append('flex', flex);
    data.append('walk', walk);
    data.append('size', size);
    data.append('cash', cash);
    data.append('guidance', guidance);
    data.append('title', title);
    data.append('text', text);
    data.append('file', file);
    
    listImg.forEach((elem, index) => {
        data.append('list_img_id[]', elem.id); 
        data.append('list_img_title[]', elem.title); 
        data.append('list_img_file_' + index, elem.img); 
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/page/apartments.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('apartments', response.data.apartments);

        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// add file csv
export function Reg_AddFileCsv(userId, file, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('type_page', 'apartments');
    data.append('edit_data', 'upload_csv');

    data.append('userId', userId);
    data.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/page/apartments.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('apartments', response.data.apartments);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// selected color apartaments
export function Reg_PointCanvas(idPage, position, color, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'apartments');
    data.append('edit_data', 'selected_color_apartament');

    data.append('idPage', idPage);
    data.append('position', position);
    data.append('color', color);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('apartments', response.data.apartments);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}