import { useState, useEffect } from "react";

import './Contents.css';

import Cookies from "universal-cookie";

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePage, typeCookiePageId, typeCookiePassw } from "fixedData";

import { SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { SvgApartments, SvgDashboardExit, SvgGallery, SvgLogoTipo, SvgLogoTipo_1, SvgMap, SvgMenuHamburguer, SvgMenuPoint, SvgProject, SvgRotation, SvgScreen, SvgService, SvgSun, SvgTour, SvgUser, SvgUserDash, SvgVideo } from "services/SvgFile";

import PopUP_ShowFile from "components/PopUp/ShowFile";
import PopUP_EditProfile from "components/PopUp/EditProfile";
import PopUP_Confirmation from "components/PopUp/Confirmation";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

import Page_UsersDash from "page/UsersDash";
import Page_UsersDash_Details from "page/UsersDash/Details";

import Page_Config from "page/Config";

import Page_ScreenInitial from "page/ScreenInitial";

import Page_Apartments from "page/Apartments";
import Page_Building from "page/Apartments/Building";
import Page_Apartments_Details from "page/Apartments/Details";

import Page_GeneralTour from "page/GeneralTour";
import Page_GeneralTour_Details from "page/GeneralTour/Details";

export default function Contents(props){
    const cookies = new Cookies();

    const [ typeDash, setTypeDash ]       = useState(GetListPag('typeDash')); 
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userFile, setUserFile ]     = useState(GetUserData('file'));
    const [ userName, setUserName ]     = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ status, setStatus ]         = useState(false); 

    function CkickPage(value){
        SetListPag('currentPage', value);
        document.getElementById('root').scrollTo({ top: 0, behavior: 'smooth' });

        if(document.body.clientWidth <= 1050){
            setStatus(true);
        }

        window.addEventListener("resize",()=>{
            if(window.innerWidth <= 1050){
                setStatus(true);
            }
        });
    }

    function Logout(){
        SetListPag('page', 'index');

        window.history.pushState("", "", "/");
        cookies.set(typeCookie, 'index', '/', cookiesRegister);
        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
        cookies.remove(typeCookiePageId, '', '/', cookiesRegister);
    }

    function CurrentPage(){
        switch (currentPage) {
            case "access_dash":
                return <Page_UsersDash setLoading={ props.setLoading } />;
            case "access_dash_details":
                return <Page_UsersDash_Details setLoading={ props.setLoading } />;
                
            case "config":
                return <Page_Config setLoading={ props.setLoading } />;

            case "screen_initial":
                return <Page_ScreenInitial setLoading={ props.setLoading } />;

            case "apartments":
                return <Page_Apartments setLoading={ props.setLoading } />;
            case "apartments_building":
                return <Page_Building setLoading={ props.setLoading } />;
            case "apartments_details":
                return <Page_Apartments_Details setLoading={ props.setLoading } />;
                
            case "general_tour":
                return <Page_GeneralTour setLoading={ props.setLoading } />;
            case "general_tour_details":
                return <Page_GeneralTour_Details setLoading={ props.setLoading } />;
        
            default:
                break;
        }
    }

    useEffect(()=>{
        RegisterListPag('typeDash', setTypeDash);
        RegisterListPag('currentPage', setCurrentPage);

        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('access', setUserAccess);

        if(document.body.clientWidth <= 1050){
            setStatus(true);
        }
        window.addEventListener("resize",()=>{
            if(window.innerWidth <= 1050){
                setStatus(true);
            }
        });

        return ()=>{
            UnRegisterListPag('typeDash', setTypeDash);
            UnRegisterListPag('currentPage', setCurrentPage);
            
            UnRegisterUserPage('file', setUserFile);
            UnRegisterUserPage('name', setUserName);
            UnRegisterUserPage('access', setUserAccess);
        };
    }, []);

    useEffect(()=>{
        setTypeDash(GetListPag('typeDash'));
        CurrentPage();
    }, [typeDash]);

    return(
        <div className="Contents">
            
            <div className={ status ? "list_menu active_menu" : "list_menu" }>
                <div className="show_opt_menu">

                    <div className="div_logo" onClick={ ()=>{ setStatus(!status) } }>
                        <div className={ status ? "div_logo_opt div_logo_opt_" : "div_logo_opt" }>
                            <div className="">
                                <img alt="logo" src="./logo192.png" className={ status ? "logotipo2" : "logotipo" } />
                            </div>
                            <div className={ status ? "icon_menu icon_menu_active" : "icon_menu" }>
                                <SvgMenuHamburguer color="#ffffff" className="icons" />
                            </div>
                        </div>                    
                    </div>
                    
                    <div className={ status ? "show_data_top" : "show_data_top show_data_top_close" }>
                        <div className="opt_menu_dash">
                            {/* {
                                userAccess == 1 ?
                                <div className={ currentPage == "access_dash" || currentPage == "access_dash_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("access_dash"); } }>
                                    <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                        <div className="div_icons">
                                            <SvgUserDash color="#FFC400" className="icons_dash" />
                                        </div>
                                        <div className={ status ? "name_page menu_close" : "name_page" }>Users</div>
                                    </div>
                                </div>
                                : null
                            } */}
                            
                            <div className={ currentPage == "config" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("config"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgService color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Settings</div>
                                </div>
                            </div>
                            
                            <div className={ currentPage == "screen_initial" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("screen_initial"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgScreen color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Screen initial</div>
                                </div>
                            </div>
                            
                            <div className={ currentPage == "apartments" || currentPage == "apartments_details" || currentPage == "apartments_building" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("apartments"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgApartments color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Apartments</div>
                                </div>
                            </div>
                            
                            <div className={ currentPage == "general_tour" || currentPage == "general_tour_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("general_tour"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgRotation color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Giro Geral</div>
                                </div>
                            </div>
                            
                            {/* 
                            <div className={ currentPage == "solar" || currentPage == "solar_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("solar"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgSun color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Solar</div>
                                </div>
                            </div>
                            
                            <div className={ currentPage == "access_dash" || currentPage == "access_dash_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("access_dash"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgUserDash color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Users</div>
                                </div>
                            </div>
                            
                            <div className={ currentPage == "gallery" || currentPage == "gallery_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("gallery"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgGallery color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Gallery</div>
                                </div>
                            </div>
                            
                            <div className={ currentPage == "floorplans" || currentPage == "floorplans_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("floorplans"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgGallery color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Floorplans</div>
                                </div>
                            </div>
                            
                            <div className={ currentPage == "videos" || currentPage == "videos_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("videos"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgVideo color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Videos</div>
                                </div>
                            </div>
                            
                            <div className={ currentPage == "vr" || currentPage == "vr_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("vr"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgTour color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>VR</div>
                                </div>
                            </div>
                            
                            <div className={ currentPage == "map_poi" || currentPage == "map_poi_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("map_poi"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgMap color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Map and POI</div>
                                </div>
                            </div> 
                            */}

                            <div className={ status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ Logout() } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgDashboardExit color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>To go out</div>
                                </div>
                            </div>
                        </div>
                        <div className="div_profile">
                            <div className={ status ? "div_profile_data div_profile_data_" : "div_profile_data" } onClick={ ()=>{ SetModalState('Profile', true); } }>
                                <div className="div_img">
                                    {
                                        userFile !='' ?
                                        <img alt="profile user" src={ "./assets/login/" + userFile } className="show_img_profile" /> :
                                        <SvgUser color="#FFFFFF" className="show_img_profile" />
                                    }
                                </div>
                                <div className={ status ? "name_user menu_close" : "name_user" }>
                                    { userName.split(' ')[0] }
                                </div>
                                <div className={ status ? "open_profile menu_close" : "open_profile" }>
                                    <SvgMenuPoint color="#ffffff" className="icons" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="list_data">
                <div className="show_data">
                    { CurrentPage() }
                </div>
            </div>

            <PopUP_ShowFile setLoading={ props.setLoading } />
            <PopUP_Confirmation setLoading={ props.setLoading } />
            <PopUP_EditProfile setLoading={ props.setLoading } />

            <PopUP_ReturnResponse setLoading={ props.setLoading } />
        </div>
    )
}