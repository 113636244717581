import React, { useState, useEffect, useRef } from "react";

import './SelectedPointCanvas.css';

import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { SvgClose } from "services/SvgFile";
import { Reg_PointCanvas } from "services/RegisterData";

export default function PopUP_SelectedPointCanvas(){

    const dataImg = useRef();
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ color, setColor ]       = useState("");
    const [ position, setPosition ] = useState({ "y": "", "x": "" });

    function SaveData(){
       Reg_PointCanvas(modalData.idPage, modalData.position, color, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        setColor("");
        setPosition({ "y": "", "x": "" });
        SetModalState('SelectedPointCanvas', false);
    }

    function getPixelColor(value) {
        let imgElement = document.getElementById('img_point');
        let canvas     = document.createElement('canvas');
        let context    = canvas.getContext('2d');

        canvas.width = imgElement.clientWidth;
        canvas.height = imgElement.clientHeight;

        context.drawImage(imgElement, 0, 0, imgElement.clientWidth, imgElement.clientHeight);

        let pixelData = context.getImageData(position.x, position.y, 1, 1).data;

        let colorSep = pixelData[0] + ", " + pixelData[1] + ", " + pixelData[2];
        setColor(colorSep)

        return {
            red: pixelData[0],
            green: pixelData[1],
            blue: pixelData[2],
            alpha: pixelData[3]
        };
    }

    useEffect(()=>{
        RegisterModalData('SelectedPointCanvas', setModaldata);
        RegisterModalObserver('SelectedPointCanvas', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            dataImg.current.addEventListener('click', (elem)=>{
                setPosition({ "y": elem.offsetY, "x": elem.offsetX, "color": "" })
            })
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all selected_point_canvas">
                        <div className="div_data type_div">
                            <div className="title">
                                Imagem lado { modalData.position + 1 }
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content color_selected">
                                <div className="obs">
                                    Clique na cor para definir o apartamento
                                </div>
                                {
                                    color == "" ? null : 
                                    <div className="div_show_color">
                                        <div className="">
                                            Cor selecionada
                                        </div>
                                        <div className="color" style={ { background: 'rgb(' + color + ')' } } />
                                        <div className="new_block_text" onClick={ ()=>{ SaveData() } }>Salvar</div>
                                    </div>
                                }
                            </div>
                            <div className="content">
                                <div className="div_mask">
                                    <img alt={ "img_" + modalData.position } src={ modalData.mask } className="img" id="img_point" onClick={ ()=>{ getPixelColor(modalData.mask) } } ref={ dataImg } crossOrigin="Anonymous" />
                                </div>
                                <div className="div_img">
                                    <img alt={ "img_" + modalData.position } src={ modalData.file } className="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
