import { useState, useEffect } from "react";

import './Apartments.css';

import { SvgAddNewData, SvgDelete, SvgEdit, SvgSave, SvgSearch, SvgSetaRight } from "services/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import { SetListPag } from "interface/Page";
import { Reg_AddFileCsv } from "services/RegisterData";

export default function Page_Apartments(props){

    const [ search, setSearch ]     = useState('');
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ showData, setShowData ] = useState(GetDataPage('apartments'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showData.slice(startItens, endItens);
    
    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('apartments').forEach(item =>{
                if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.status.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowData(duplicate);
        }else {
            setShowData(GetDataPage('apartments'));
        }
        setSearch(value);
    }

    function PageBuilding(){
        SetListPag('currentPage', 'apartments_building');
    }

    function AddFileCsv(value){
        props.setLoading(true);
        Reg_AddFileCsv(userId, value, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'apartments_details');
        SetListPag('currentPageId', idPage);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "apartments", "type" : "delete_data", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('apartments', setShowData);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('apartments', setShowData);
        };
    }, []);

    return(
        <div className="Page_Apartments">
            <div className="new_block">
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />
                </div>
                <div className="list_opt_alt_page">
                    <label className="div_add_new_data div_new_data">
                        <div className="">adicionar CSV</div>
                        <input type="file" className="input_file" onChange={ (e)=>{ AddFileCsv(e.target.files[0]); } } />
                    </label>
                    <div className="div_add_new_data" onClick={ ()=>{ PageBuilding() } }>
                        <div className="">fotos do prédio</div>
                    </div>
                    <div className="list_opt_alt_page" onClick={ ()=>{ PageClick(0) } }>
                        <div className="div_add_new_data new_space_add">
                            <div className="new_data_add">
                                <SvgAddNewData color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">apartamento</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Título</th>
                            <th width="80">Andar</th>
                            <th width="80">Número</th>
                            <th width="80">Valor</th>
                            <th width="80">Status</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.title }</td>
                                        <td>{ elem.walk }</td>
                                        <td>{ elem.number }</td>
                                        <td>{ elem.cash }</td>
                                        <td>{ elem.status }</td>
                                        <td align="right">
                                            <div className="list_opt">
                                                <div onClick={ ()=>{ DeleteData(elem.id, elem.title) } } title="Deletar dado">
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                                <div onClick={ ()=>{ PageClick(elem.id) } } title="Editar dado">
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    showData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}