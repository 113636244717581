import { useState, useEffect } from "react";

import './Details.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { Reg_GeneralTour } from "services/RegisterData";
import { SvgDelete, SvgImg, SvgStar } from "services/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import PointImg from "components/PointImg";
import TopicPage from "components/TopicPage";

export default function Page_GeneralTour_Details(props){

    const [ userId, setUserId ]         = useState(GetUserData('id'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));

    const [ dataPage, setDataPage ] = useState(GetDataPage("general_tour"));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ title, setTitle ]     = useState(InitialData('title'));
    const [ cover, setCover ]     = useState(InitialData('cover'));
    const [ status, setStatus ]   = useState(false);
    const [ text, setText ]       = useState(InitialData('text'));
    const [ listImg, setListImg ] = useState(InitialData('list_img'));

    const [ listPoint, setListPoint ] = useState(InitialData('point_img'));

    const [ compr, setCompr ]             = useState([]);
    const [ statusCompr, setStatusCompr ] = useState(false);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'list_img'){
            return [];
        }
        return '';
    }

    function AddFile(type, value){
        // cover
        if(type == 'cover'){
            if(value){
                setCover(value);
                setStatus(true);
            }else {
                setCover('');
                setStatus(false);
            }
        }
        // file compr
        if(type == 'compr'){
            if(value){
                setCompr(value);
                setStatusCompr(true);
            }else {
                setCompr('');
                setStatusCompr(false);
            }
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(type, id, value){
        SetModalData('Confirmation', { "origin": "general_tour", "type" : type, "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function DeletePoint(id, index, index_1, value){
        if(id == 0){
            const newData = [...listPoint];
            newData[index]['point'].splice(index_1, 1);
            setListPoint(newData);
        }else {
            SetModalData('Confirmation', { "origin": "general_tour", "type" : "delete_point", "id" : id, "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function HandlePoint(type, index, index_1, value){
        const newData = [...listPoint];
        if(type == 'file'){
            if(value){
                newData[index]['point'][index_1]['file']   = value;
                newData[index]['point'][index_1]['status'] = true;
            }else {
                newData[index]['point'][index_1]['file']   = '';
                newData[index]['point'][index_1]['status'] = false;
            }
        }else{
            newData[index]['point'][index_1][type] = value;
        }
        setListPoint(newData);
    }

    function HandleData(type, index, value){
        const newData = [...listPoint];
        newData[index][type] = value;
        setListPoint(newData);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_GeneralTour(userId, idPage, title, cover, text, compr, listPoint, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'general_tour');
        SetListPag('currentPageId', 'remuve');
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterUserData('access', setUserAccess);

        RegisterDataPage('general_tour', setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterUserPage('access', setUserAccess);

            UnRegisterDataPage('general_tour', setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("general_tour"));
        setIdPage(GetListPag('currentPageId'));

        setTitle(InitialData('title'));
        setCover(InitialData('cover'));
        setStatus(false);
        setText(InitialData('text'));
        setListImg(InitialData('list_img'));
        setListPoint(InitialData('point_img'))

        setCompr([]);
        setStatusCompr(false);
    }, [dataPage, idPage]);
    
    return(
        <div className="Page_GeneralTour_Details">
            <form onSubmit={ SaveData }>
                <TopicPage type="1" ReturnPage={ ReturnPage } title="Detalhes do giro geral" />

                <div className="show_page_data">
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="16" required />
                            <span className="name_input">Título</span>
                        </div>
                        <span className="div_input div_add_img">
                            <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { status == true ? "Capa adicionada" : "Adicionar capa" }
                                    <input type="file" onChange={ (e)=>{ AddFile('cover', e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                cover !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile('cover', ''); } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        status == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(cover)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', 'general_tour/cover/' + cover) } }>
                                            <img alt="img_" src={ './assets/general_tour/cover/thumbnail/thumbnail_' + cover } className="icons_file" />
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Observação</div>
                            <JoditEditor config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                        </div>
                    </div>
                </div>

                {/* {
                    listPoint.length == 0 ? null :
                    <PointImg folder="general_tour" data={ listPoint } HandleData={ HandleData } DeletePoint={ DeletePoint } HandlePoint={ HandlePoint } OpenFile={ OpenFile } code={ code } />
                } */}

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Fotos do giro geral</div>
                        <div className="div_add_img">
                            {
                                listImg.length == 0 ? null :
                                <div className="delete_all" onClick={ ()=>{ DeleteData('delete_all_img', idPage, "Todas as imagens") } }>
                                    Deletar todas as imagens
                                </div>
                            }

                            <div className={ statusCompr == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { statusCompr == true ? "Imagens adicionadas" : "Adicionar imagens" }
                                    <input type="file" onChange={ (e)=>{ AddFile('compr', e.target.files); } } accept="image/*" multiple />
                                </label>
                            </div>
                            {
                                statusCompr == '' ? null :
                                <div className="delete_img_add" onClick={ ()=>{ AddFile('compr', ''); } }>
                                    <SvgDelete className="icons" color="#ffffff" />
                                </div>
                            }
                        </div>
                    </div>

                    <div className="list_input_data">
                        {
                            listImg.map((elem, index)=>{
                                return(
                                    <div className="div_list_file" key={ index }>
                                        <div className="numb_img">{ index + 1 }</div>
                                        <div className="name_img">
                                            { elem.img }
                                            {
                                                elem.img_point == 0 ? null :
                                                
                                                elem.img_point == 1 ? 
                                                <div data-tooltip-id={ "type_data_" + elem.id } data-tooltip-content="Pontos e dados adicionados!" data-tooltip-place="top">
                                                    <SvgStar className="icons" color="#0b711c" />
                                                </div> : 
                                                <div data-tooltip-id={ "type_data_" + elem.id } data-tooltip-content="Pontos adicionados sem conteúdo" data-tooltip-place="top">
                                                    <SvgStar className="icons" color="#f00000" />
                                                </div>
                                            }
                                            <Tooltip id={ "type_data_" + elem.id } />
                                        </div>
                                        <div className="delete_img">
                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', 'general_tour/' + elem.img) } }>
                                                <img alt="img_" src={ './assets/general_tour/thumbnail/thumbnail_' + elem.img } className="icons_file" />
                                            </div>
                                        </div>
                                        <div className="delete_img" onClick={ ()=>{ DeleteData('delete_img', elem.id, elem.img) } }>
                                            <SvgDelete color="#f00000" className="icons" />
                                        </div>
                                    </div>
                                )
                            })
                        }                             
                    </div>
                </div>
            </form>
        </div>
    )
}